import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { JwtInterceptor } from '@helpers/jwt.interceptor';
import { ErrorInterceptor } from '@helpers/error.interceptor';
import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { ModalDeleteComponent } from '@components/modals/modal-delete/modal-delete.component';
import { ConfirmModalComponent } from '@components/modals/confirm-modal/confirm-modal.component';
import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { InspectionModalComponent } from "@components/modals/inspection-modal/inspection-modal.component";
import { PhotosModalComponent } from "@components/modals/photos-modal/photos-modal.component";
import { InsuredValueComponent } from "@components/modals/insured-value/insured-value.component";
import { PaymentsModalComponent } from "@components/payments-modal/payments-modal.component";




registerLocaleData(localeEsAr, 'es-Ar');
@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgbModule
  ],
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent],
  providers: [
    DecimalPipe,
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  entryComponents: [ModalDeleteComponent, ConfirmModalComponent, InspectionModalComponent, PhotosModalComponent, InsuredValueComponent, PaymentsModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
