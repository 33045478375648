import { Component, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AseguradosService } from '@services/asegurados.service';
import { EventService } from '@services/event.service';
import { PoliciesService } from '@services/policies.service';

@Component({
  selector: 'app-payments-modal',
  templateUrl: './payments-modal.component.html',
  styleUrls: ['./payments-modal.component.scss']
})
export class PaymentsModalComponent implements OnInit {
  @Input() user: string;
  @Input() policyId: string;
  @Input() credit_card: string;
  
  payments: any;
  constructor(
    private aseguradosService: AseguradosService,
    private modalService: NgbModal,
    private policiesService: PoliciesService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getPayments();
  }

  setPayment(paymentId: string){
      this.credit_card = paymentId;
  }

  close(){
    this.modalService.dismissAll();
  }

  isSelected(paymentId: string){
    return (paymentId == this.credit_card) ? true : false;
  }

  getPayments(){
    this.aseguradosService.getCardsById(this.user).subscribe({
      next: response => {
        this.payments = response.data;
        console.log(response);
      },

      error: error => {
        console.log(error);
      }
    });
    
  }

  updatePayment(){
    this.policiesService.updateCreditCard(this.credit_card, this.policyId).subscribe({
      next: response => {
        this.eventService.emit('update');
        this.modalService.dismissAll();
      },

      error: error => {
        console.log(error);
      }
    });
  }


}
