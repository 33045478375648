import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class QuotesService {
	private headers: any;

	constructor(
		private http: HttpClient,
	) {
		this.headers = new HttpHeaders();
		this.headers = this.headers.set('Content-Type', 'application/json');
	}

	public get(offset, query: string): Observable<any> {
		return this.http.get(`${environment.apiUrl}quotes?offset=${offset}&limit=10&query=${query}`, { headers: this.headers });
	}

	public getBrands(sectionId: number): Observable<any> {
		return this.http.get(`${environment.apiUrl}models/brand/${sectionId}`, { headers: this.headers });
	}
	

	public getModels(brand: number, sectionId: number): Observable<any> {
		return this.http.get(`${environment.apiUrl}models/${brand}/models/${sectionId}`, { headers: this.headers });
	}

	public getYears(code: string): Observable<any> {
		return this.http.get(`${environment.apiUrl}models/${code}/years`, { headers: this.headers });
	}

	public getQuote(brand: string, code: string, year: string, is0km: boolean): Observable<any> {
		return this.http.get(`${environment.apiUrl}quotes/models?brand=${brand}&code=${code}&year=${year}&is0km=${is0km}`, { headers: this.headers });
	}
	public saveQuote(params: any): Observable<any> {

		return this.http.put(`${environment.apiUrl}quotes/${params.id}`, {
			coverage_type: params.coverage_type,
			garage: params.garage,
			engine: params.engine,
			chasis: params.chasis,
			plate: params.plate,
			credit_card_id: params.credit_card_id,
			refer_code: params.refer_code,
			dni: params.dni,
			address: params.address,
			birth_date: params.birth_date,
			code: params.code
		}, { headers: this.headers });
	}
	public getQuoteByAgent(offset, agentId): Observable<any> {
		return this.http.get(`${environment.apiUrl}quotes?agent=${agentId}offset=${offset}&limit=10`, { headers: this.headers });
	}

	public getQuoteByClient(offset, clientId): Observable<any> {
		return this.http.get(`${environment.apiUrl}quotes?client_id=${clientId}&offset=${offset}&limit=10`, { headers: this.headers });
	}

	public getPolicies(params: any): Observable<any> {
		
			return this.http.post(`${environment.apiUrl}quotes`, {
				code: params.code,
				brand: params.brand,
				year: params.year,
				zone_id: params.zone_id,
				client_email: params.email,
				client_first_name: params.firstname,
				client_last_name: params.lastname,
				section: params.section,
				value: params.value,
				model: params.model,
				client_id: params.userId
			}, { headers: this.headers });
		
		
	}

	public getClassicCarPolicies(params: any): Observable<any> {
		
		return this.http.post(`${environment.apiUrl}quotes/v2/classic-cars`, {
			code: params.code,
			brand: params.brand,
			year: params.year,
			zone_id: params.zone_id,
			client_email: params.email,
			client_first_name: params.firstname,
			client_last_name: params.lastname,
			section: params.section,
			value: params.value,
			model: params.model,
			client_id: params.userId
		}, { headers: this.headers });
	
	
}



	public emit(params: any): Observable<any> {
		return this.http.post(`${environment.apiUrl}quotes/emit/${params.quote_id}`, {
			card_number: params.card_number,
			card_name: params.card_name,
			card_expiration: params.card_expiration,
			type: params.type,
			channel: 'PAS'
		}, { headers: this.headers });
	}

	public getType(cardNumber: string) {
		// visa
		let re = new RegExp('^4');
		if (cardNumber.match(re) != null) { return 'visa'; }

		// Mastercard
		// Updated for Mastercard 2017 BINs expansion
		if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(cardNumber)) { return 'mastercard'; }

		// AMEX
		re = new RegExp('^3[47]');
		if (cardNumber.match(re) != null) { return 'amex'; }

		return '';
	}

	public getById(id): Observable<any> {
		return this.http.get(`${environment.apiUrl}quotes/` + id, { headers: this.headers });
	}

	public getZone(cp: string) {
		return this.http.get(`${environment.apiUrl}zones?postal_code=${cp}`, { headers: this.headers });
	}

	public download(id: number) {
		return this.http.get(`${environment.apiUrl}proposals/${id}/download`, { headers: this.headers });
	}
	public getSections() {
		return this.http.get(`${environment.apiUrl}models/sections`, { headers: this.headers });
	}

	public setInsuredAmount(quoteId: number, value: number): Observable<any> {

		return this.http.post(`${environment.apiUrl}quotes/${quoteId}`, {
			value: value,
		}, { headers: this.headers })
	}
}
