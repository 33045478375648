import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { User } from '@models/user.model';
import { environment } from '@environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']

})
export class AppComponent {
	currentUser: User;
	title = 'Activar';
	isProduction: boolean;
	apiUrl: string;
	constructor(
        private router: Router,
        private AuthService: AuthService
    ) {
    	this.isProduction = environment.production;
		this.apiUrl = environment.apiUrl;
    }

}
